<template>
  <MiscellaneousInventorySource dialog-name="Inventory Sources" />
</template>
<script>
import MiscellaneousInventorySource from '@/views/app/miscellaneous/InventorySource'

export default {
  name: 'InventorySourceWrapper',
  components: {
    MiscellaneousInventorySource
  }
}
</script>
